<template>
    <div class="top_index" :class="$store.state.menuShow ? 'show' : ''">
        <div class="top_content">
            <div class="collapse" @click="$store.commit('menuShowChange')">
                <i :class="$store.state.menuShow ? 'el-icon-s-fold' : 'el-icon-s-unfold'" />
            </div>
            <div class="title">
                <!-- 天津市双街镇社区卫生服务中心 -->
                {{name}}
                <UserBtn></UserBtn>
            </div>
        </div>
    </div>
</template>
<script>
    import UserBtn from './UserBtn'
    export default {
        components: {
            UserBtn
        },
        data() {
            return {
                vcal: 0,
                name: ""
            }
        },
        watch: {
            vcal(value) {

                // console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAAA", document.body.clientWidth);
                this.$store.commit('menuShowChange')

            }
        },

        mounted() {

            this.name = JSON.parse(localStorage.getItem("user")).dep_name

            window.addEventListener('resize', () => {
                if (document.body.clientWidth < 800) {
                    this.vcal = 1
                } else {
                    this.vcal = 0
                }
            })

        },
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/var.scss';

    .top_index {
        height: $--top-height;
        box-shadow: $--layout-shadow;
        background: #fff;
        transition: width 0.3s;
        width: calc(100vw - 64px);

        position: relative;

        .top_content {
            padding: 0 20px;
            display: flex;
            align-items: center;
            height: 100%;

            .collapse {
                margin-right: 10px;
                cursor: pointer;

                &:hover {
                    color: $--color-primary;
                }
            }

            .title {
                font-size: 14px;
            }
        }

        &.show {
            width: calc(100vw - #{$--menu-width});
        }
    }
</style>