<template>
    <div class="btnBox">

        <el-dropdown trigger="click">
            <span class="el-dropdown-link headbox">

                <!-- <img v-bind:src="msg" alt=""> -->

                <img v-if="msg" :src="GLOBAL.BASE_URL +'/uploads' + msg" alt="">
                <div v-else class="img"></div>





            </span>
            <el-dropdown-menu slot="dropdown" class="headdropbox">

                <el-dropdown-item disabled style="padding-left: 50px;">个人中心</el-dropdown-item>
                <el-dropdown-item @click.native="userinfo"><i class="fa fa-edit"
                        aria-hidden="true"></i>编辑资料</el-dropdown-item>
                <!-- <el-dropdown-item><i class="fa fa-unlock-alt" aria-hidden="true"></i>修改密码</el-dropdown-item> -->

                <el-dropdown-item @click.native="quit"><i class="fa fa-sign-out"
                        aria-hidden="true"></i>退出</el-dropdown-item>

            </el-dropdown-menu>
        </el-dropdown>


    </div>



    </div>
</template>


<script>
    import * as API from '@/utils/index.js'


    export default {
        name: 'UserBtn',
        data() {
            return {
                // visible: false,
                msg: ''
            }
        },
        created() {
            // this.msg = 'http://iph.href.lu/100x100?text=' + JSON.parse(localStorage.getItem("user"))
            // .realname.slice(0,2) + '&fg=ffffff&bg=748EFC'


            this.msg = JSON.parse(localStorage.getItem("user")).avatar


        },
        methods: {

            // 跳转用户
            userinfo() {
                this.$router.push("/pages/User/User")
            },

            // 退出
            quit() {

                this.$confirm('确定要退出登录吗？', '提示', {
                    // confirmButtonText: '确定',
                    // cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {


                    // 退出接口
                    API.logoutAPI()
                        .then((res) => {

                            console.log(res)

                            localStorage.clear(); //清空
                            // 跳转到登录页面
                            this.$router.push('/')



                            setTimeout(() => {
                                this.$router.go(0)
                            }, 100)


                        }).catch((err) => {
                            console.log(err)
                        });


                }).catch(() => {
                    //   this.$message({
                    //     type: 'info',
                    //     message: '已取消退出'
                    //   });
                });

            },


        }
    }
</script>

<style>
    .btnBox {
        /* border: 1px red dotted;
        box-sizing: border-box; */

        width: 70px;
        height: 70px;
        /* float: right;
clear: both; */
        /* display: inline-block; */
        /* text-align: right; */
        position: absolute;
        right: 10px;
        top: 0;

        /* text-align: center; */
        display: flex;
        align-items: center;
        justify-content: center;

    }


    .headbox {
        /* border: 1px red dotted;
        box-sizing: border-box; */

        border-radius: 50%;
        display: block;
        width: 50px !important;
        height: 50px !important;
        /* margin-top: 5px; */
        overflow: hidden;
        padding: 0;



    }

    .headbox .img,
    .headbox img {
        object-fit: cover;
        width: 100%;
        height: 100%;


        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;


        /* background-image: url('http://iph.href.lu/100x100?text=你好&fg=ffffff&bg=748EFC'); */

        background-image: url('../../../../public/hello.png');

    }

    .headdropbox {

        /* border: 1px red dotted;
    	box-sizing: border-box; */

        box-shadow: #0006 5px 5px 5px;

        border-radius: 5px;
        width: 150px;
        padding: 0px 0 5px 0;
        overflow: hidden;
    }

    .headdropbox .el-dropdown-menu__item {
        padding-left: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #576193;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .headdropbox .el-dropdown-menu__item.is-disabled {
        background-color: #576193;
        color: white;
    }

    .headdropbox .popper__arrow::after {
        content: none;
    }
</style>